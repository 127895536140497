// i18next-extract-mark-ns-start apple-pay-for-woocommerce
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import woocommerce_applepay from 'images/woocommerce_apple-pay.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {
  InternalPageLink,
  PaymentsOrchestrationLink,
  RedsysAlternativeLink
} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="apple-pay-for-woocommerce"
        title={t('Add Apple Pay to WooCommerce | MONEI')}
        description={t(
          'Add Apple Pay to your WooCommerce store to let iOS users pay with one of the most used digital wallets in the world. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Quickly and easily add Apple Pay to your WooCommerce store</Trans>
              </SectionHeader>
              <Trans parent="p">
                With over 500 million users worldwide, and growing, it’s essential to add Apple Pay
                to your WooCommerce store. But to get started, you’ll need a payment gateway that
                supports Apple Pay and integrates with WooCommerce.
              </Trans>
              <Trans parent="p">
                Use our advanced e-commerce payment gateway to add all major and alternative payment
                methods, including Apple Pay, to your WooCommerce store. And enjoy managing it all
                from a single dashboard.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              src={woocommerce_applepay}
              alt="Woocommerce apple pay"
              title="Woocommerce apple pay"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept Apple Pay payments and many other WooCommerce payments</Trans>
              </SectionHeader>
              <Trans parent="p">
                Accepting{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink> will
                help you reach more customers and increase conversions, but there are also more{' '}
                <InternalPageLink slug="payment-methods">online payment methods</InternalPageLink>{' '}
                to consider. Modern consumers look for many payment options including{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  credit and debit cards
                </InternalPageLink>
                , additional <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink>{' '}
                such as{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>, and{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                , and <BlogLink slug="local-payment-methods">local payment methods</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in Spain.
              </Trans>
              <Trans parent="p">
                Boost sales and customer satisfaction by accepting a wide range of payment methods.
                And manage all your transactions from one easy-to-use platform.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Improve your conversion rate with payments orchestration</Trans>
              </SectionHeader>
              <Trans parent="p">
                Provide a <BlogLink slug="frictionless-payments">frictionless payment</BlogLink>{' '}
                experience, approve more payments, and improve sales thanks to{' '}
                <PaymentsOrchestrationLink>payments orchestration</PaymentsOrchestrationLink>.
                You’ll be able to choose a default payment processor and set up payment routing
                rules. This way, if <RedsysAlternativeLink>Redsys</RedsysAlternativeLink> is
                experiencing downtime, transactions will get sent to another processor — reducing
                unnecessary{' '}
                <BlogLink slug="online-payment-failure-message">payment failure</BlogLink> messages
                and lost sales.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Enjoy the lowest WooCommerce payment gateway pricing</Trans>
              </SectionHeader>
              <Trans parent="p">
                As your sales revenue increases, you’ll spend less money on transaction fees thanks
                to our dynamic pricing model. We want to help you build your business, that’s why
                we’re the only payment gateway to offer scaled pricing for platform usage.
              </Trans>
              <Trans parent="p">
                Choose the most flexible payments platform —{' '}
                <Link to="/pricing/">view pricing here</Link>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>How to add Apple Pay to WooCommerce</Trans>
            </SectionHeader>
            <Trans parent="p">
              Quickly and easily integrate your WooCommerce shop with our payment gateway to start
              accepting Apple Pay (and more) payments today.
            </Trans>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/es-es/sections/360005144598-Apple-Pay">
                  Configure Apple Pay payments
                </AnchorLink>{' '}
                in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                Follow the{' '}
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/woocommerce/">
                  WooCommerce plugin guide
                </AnchorLink>{' '}
                to connect MONEI to your online store
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "apple-pay-for-woocommerce"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
